import React from "react";
import client from "../lib/api/apollo";
import "keen-slider/keen-slider.css";
import { appWithTranslation, SSRConfig } from "next-i18next";
import { AppProps } from "next/app";
import { CustomThemeProvider } from "../lib/context/theme/CustomThemeProvider";
import { ApolloProvider } from "@apollo/client";
import { SessionProvider } from "../lib/auth/client/SessionProvider";
import { NewsProvider } from "../lib/context/news/NewsProvider";
import { OrganizationProvider } from "../lib/context/organization/OrganizationProvider";
import { Matomo } from "../lib/matomo";
import { NextPageWithLayout } from "../type/app";

interface PageProps extends SSRConfig {
    session?: Auth.Session;
    organization?: Organization.Index;
    facets?: Filters.Facets;
}

interface AppPropsWithLayout extends AppProps<PageProps> {
    Component: NextPageWithLayout<PageProps | {}>;
}

const App: React.FC<AppPropsWithLayout> = ({ Component, pageProps }) => {
    const getLayout = Component.getLayout || (page => page);

    return (
        <SessionProvider initialSession={pageProps.session} redirectUrl="/api/auth/login">
            <ApolloProvider client={client}>
                <CustomThemeProvider>
                    <OrganizationProvider organization={pageProps.organization}>
                        <NewsProvider initialFacets={pageProps.facets}>
                            <Matomo />
                            {getLayout(<Component {...pageProps} />)}
                        </NewsProvider>
                    </OrganizationProvider>
                </CustomThemeProvider>
            </ApolloProvider>
        </SessionProvider>
    );
};

export default appWithTranslation(App);
